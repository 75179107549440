<template>
  <div class="fit-chart">
    <b-list-group class="chart-list">
      <b-list-group-item
        v-for="(value, index) in element.element_values"
        :key="index"
        class="py-1 pl-0"
      >
        <div class="d-flex align-self-center">
          <div :style="{ width: chartWidth(value) }" v-if="canShowBar(value)">
            <div :class="['fit-chart-bar d-flex align-self-center']"></div>
          </div>
          <div>
            <span :class="[canShowBar(value) ? 'pl-2' : '', 'text-muted']">
              <span>
                {{ value.fi_percent | percent_strict }}
              </span>
            </span>
          </div>
        </div>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script charset="utf-8">
export default {
  name: 'FitChart',
  props: {
    element: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    maxVal() {
      return Math.max(
        ...this.element.element_values.map(ev =>
          Math.round(ev.fi_percent * 100)
        )
      )
    }
  },
  methods: {
    chartWidth(element) {
      let valPercent = Math.round(element.fi_percent * 100)
      return Math.round((valPercent / this.maxVal) * 90) + '%' // multiplied by 90 instead of 100 to consider to space used by plot label
    },
    canShowBar(element) {
      let valPercent = Math.round(element.fi_percent * 100)
      return valPercent > 0
    }
  }
}
</script>

<style lang="scss" scoped>
.fit-chart {
  .fit-chart-bar {
    color: #a7ca77;
    background-color: #a7ca77;
    height: 20px;
  }

  .chart-list {
    .list-group-item {
      border-color: white;
    }
  }
}
</style>
