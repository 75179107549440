<template>
  <div class="ranking-card">
    <v-wait for="loadingVendorProducts">
      <template slot="waiting">
        <content-placeholders :rounded="true" class="loader">
          <content-placeholders-heading />
          <content-placeholders-text :lines="10"></content-placeholders-text>
        </content-placeholders>
      </template>
      <div class="row">
        <div class="col-sm-6 rankings pr-1">
          <b-card
            class="std-border no-hover-highlt mb-0"
            header-class="p-2 px-3"
            body-class="p-2"
          >
            <template #header>
              <div class="">
                <h6 class="mb-0 font-weight-bold title-header">Product Fit</h6>
                <div class="mb-0">
                  Regression of Independent Variables Without Product
                  Integrations
                </div>
                <h6 class="mb-0"></h6>
              </div>
            </template>
            <div>
              <div class="row mt-2">
                <div class="col-sm-12">
                  <div class="d-flex justify-content-between">
                    <div class="d-flex">
                      <header-logo
                        logoType="fi"
                        :logoUrl="fiLogoURL"
                        :logoId="selectedInstitution.id"
                        class="justify-content-center mb-2"
                      ></header-logo>
                      <div class="pl-0">
                        <h6 class="mb-0">
                          {{ profileDetail.name }}, {{ profileDetail.city }},
                          {{ profileDetail.state }}
                        </h6>
                        <div>
                          <span
                            class="site-link"
                            v-if="
                              profileDetail.website_url &&
                                profileDetail.website_url !== '0'
                            "
                          >
                            <a
                              target="_blank"
                              :href="formatLink(profileDetail.website_url)"
                            >
                              {{ profileDetail.website_url }}
                              <sup>
                                <i
                                  class="fa fa-external-link"
                                  aria-hidden="true"
                                ></i>
                              </sup>
                            </a>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr class="m-1 mb-2" />

              <div class="row pt-1 pb-2 no-gutters paginators">
                <div class="col-sm-4"></div>
                <div class="col-sm-6 vendor-limit pr-3">
                  <div class="row align-items-center">
                    <div class="col-sm-6 text-right">
                      Showing top
                    </div>
                    <div class="col-sm-6 pl-1">
                      <div class="input-group">
                        <input
                          type="number"
                          class="form-control"
                          v-model="vendorLimitTmp"
                        />
                        <div class="input-group-append vendor-control">
                          <span
                            class="input-group-text cursor-pointer"
                            id="basic-addon2"
                            @click="updateVendors"
                            >Set</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="col-sm-2 px-2 align-self-center show-all-link text-center"
                >
                  <span class="look-as-a-tag" @click="setAllVendors"
                    >Show all</span
                  >
                </div>
              </div>
              <div class="row no-gutters">
                <div class="col-sm-12">
                  <v-wait for="updatingVendors">
                    <template slot="waiting">
                      <content-placeholders :rounded="true" class="loader">
                        <content-placeholders-heading />
                        <content-placeholders-text
                          :lines="9"
                        ></content-placeholders-text>
                      </content-placeholders>
                    </template>
                    <b-table
                      sticky-header="17rem"
                      striped
                      hover
                      show-empty
                      :tbody-tr-class="selectedVendorClass"
                      :items="vendors"
                      :fields="vendorFields"
                      selectable
                      select-mode="single"
                      @row-selected="selectVendor"
                    >
                      <template #cell(vendor_name)="data">
                        <div class="d-flex align-items-baseline">
                          <div
                            :class="[
                              solutionDetail.vendor_id ===
                                data.item.vendor_id &&
                              solutionDetail.product_id === data.item.product_id
                                ? 'current-vendor-dot mx-1'
                                : 'empty-dot mx-1'
                            ]"
                          ></div>
                          <div>
                            {{ data.item.vendor_name }}
                          </div>
                        </div>
                      </template>
                      <template #cell(confidence)="data">
                        {{ data.item.confidence }}%
                      </template>
                      <template #cell(actions)="data">
                        <span>
                          <i class="fa fa-arrow-right"></i>
                        </span>
                      </template>
                    </b-table>
                  </v-wait>
                </div>
              </div>
            </div>
          </b-card>
        </div>
        <div class="col-sm-6 vendors pl-1">
          <div v-if="selectedVendor">
            <div>
              <b-card
                class="std-border no-hover-highlt mb-0"
                header-class="p-2 px-3"
                body-class="py-1"
              >
                <template #header>
                  <div class="">
                    <h6 class="mb-0 font-weight-bold title-header">
                      Client Institutions
                    </h6>
                    <div class="">Ranked by Level of Product Fit</div>
                  </div>
                </template>
                <div class="row mt-2">
                  <div class="col-sm-12">
                    <div class="d-flex justify-content-between">
                      <div class="d-flex">
                        <header-logo
                          logoType="vendor"
                          :logoUrl="vendorLogoURL"
                          :logoId="selectedVendor.vendor_id"
                          class="justify-content-center mb-2"
                        ></header-logo>
                        <div class="pl-0">
                          <h6 class="mb-0">
                            {{ selectedVendor.vendor_name }} |
                            {{ selectedVendor.product_name }}
                          </h6>
                          <div>
                            <span
                              class="site-link"
                              v-if="
                                selectedVendor.vendor_url &&
                                  selectedVendor.vendor_url !== '0'
                              "
                            >
                              <a
                                target="_blank"
                                :href="formatLink(selectedVendor.vendor_url)"
                              >
                                {{ selectedVendor.vendor_url }}
                                <sup>
                                  <i
                                    class="fa fa-external-link"
                                    aria-hidden="true"
                                  ></i>
                                </sup>
                              </a>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="text-center pr-2">
                        <h4 class="mb-0 font-weight-bold">
                          {{ selectedVendor.no_of_banks }}
                        </h4>
                        <h6 class="mb-0 font-weight-bold">
                          Client Institutions
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
                <hr class="m-1 mb-2" />

                <div class="row pt-1 pb-2 no-gutters paginators">
                  <div class="col-sm-4"></div>
                  <div class="col-sm-6 fi-limit pr-3">
                    <div class="row align-items-center">
                      <div class="col-sm-6 text-right">
                        Showing top
                      </div>
                      <div class="col-sm-6 pl-1">
                        <div class="input-group">
                          <input
                            type="number"
                            class="form-control"
                            v-model="fiLimit"
                          />
                          <div class="input-group-append fi-control">
                            <span
                              class="input-group-text cursor-pointer"
                              id="basic-addon2"
                              @click="updateFIs"
                              >Set</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-sm-2 px-2 align-self-center show-all-link text-center"
                  >
                    <span class="look-as-a-tag" @click="fetchAllFIs"
                      >Show all</span
                    >
                  </div>
                </div>
                <div class="row mt-1">
                  <div class="col-sm-12">
                    <v-wait for="loadingVendorInstitutions">
                      <template slot="waiting">
                        <content-placeholders :rounded="true" class="loader">
                          <content-placeholders-heading />
                          <content-placeholders-text
                            :lines="9"
                          ></content-placeholders-text>
                        </content-placeholders>
                      </template>
                      <b-table
                        sticky-header="17.2rem"
                        striped
                        hover
                        show-empty
                        :items="institutions"
                        :fields="institutionFields"
                      >
                      </b-table>
                    </v-wait>
                  </div>
                </div>
              </b-card>
            </div>
          </div>
        </div>
      </div>
    </v-wait>
  </div>
</template>
<script charset="utf-8">
// global
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import { createHelpers } from 'vuex-map-fields'
// ui components
import HeaderLogo from '@/modules/core/components/HeaderLogo'
// utils
import { formatUrl } from '@/utilities'

const { mapFields } = createHelpers({
  getterType: 'VendorFit/getField',
  mutationType: 'VendorFit/updateField'
})

export default {
  name: 'ProductFitRanking',
  props: {
    renderedForPDF: {
      type: Boolean,
      default: false
    }
  },
  components: {
    HeaderLogo
  },
  created() {
    this.vendorLimitTmp = this.vendorLimit
  },
  data() {
    return {
      vendorLimitTmp: 0,
      sectionName: 'Product Fit Ranking'
    }
  },
  computed: {
    ...mapState('VendorFit', {
      vendorLimit: state => state.vendorLimit,
      showAllFIs: state => state.showAllFIs,
      profileDetail: state => state.profileDetail,
      selectedVendor: state => state.selectedVendor,
      solutionDetail: state => state.solutionDetail,
      clientInstitutions: state => state.clientInstitutions,
      selectedInstitution: state => state.selectedInstitution,
      vendorProducts: state => state.vendorProducts,
      allInstitutions: state => state.allInstitutions
    }),
    ...mapGetters('VendorFit', ['fiLogoURL', 'vendorLogoURL']),
    ...mapFields(['fiLimit']),
    vendors() {
      return this.renderedForPDF
        ? this.vendorProducts
        : this.vendorProducts.slice(0, this.vendorLimit)
    },
    institutions() {
      return this.renderedForPDF
        ? this.allInstitutions
        : this.clientInstitutions
    },
    vendorFields() {
      return [
        { key: 'vendor_name', label: 'Vendor', sortable: true, class: 'pl-1' },
        { key: 'product_name', label: 'Product', sortable: true },
        { key: 'confidence', label: 'Fit%', sortable: true },
        { key: 'actions', label: '', sortable: false }
      ]
    },
    institutionFields() {
      return [
        { key: 'id', label: 'FI ID', sortable: true },
        { key: 'name', label: 'Name', sortable: true },
        { key: 'city', label: 'City', sortable: true },
        { key: 'state', label: 'ST', sortable: true },
        { key: 'confidence', label: 'Fit%', sortable: true },
        { key: 'rank', label: 'Rank', sortable: true }
      ]
    }
  },
  methods: {
    ...mapActions('VendorFit', [
      'fetchVendorProducts',
      'fetchClientInstitutions'
    ]),
    ...mapMutations('VendorFit', [
      'setFILimit',
      'setVendorLimit',
      'setShowAllFIs',
      'setShowAllVendors',
      'setSelectedVendor',
      'setClientInstitutions'
    ]),
    configChanged() {
      this.$wait.start('loadingVendorProducts')

      this.loadVendorAndInstitutions().then(() => {
        this.$wait.end('loadingVendorProducts')
      })
    },
    async loadVendorAndInstitutions() {
      await this.loadVendors()
      return this.loadVendorInstitutions()
    },
    loadVendors() {
      this.$wait.start('updatingVendors')

      return this.fetchVendorProducts({
        fiID: this.selectedInstitution.id,
        limit: null
      }).then(() => {
        if (!this.selectedVendor) {
          this.setClientInstitutions(new Array())
          this.setSelectedVendor(this.vendorProducts[0])
        }
        this.$wait.end('updatingVendors')
      })
    },
    loadVendorInstitutions() {
      this.$wait.start('loadingVendorInstitutions')

      return this.fetchClientInstitutions({
        productID: this.selectedVendor.product_id,
        limit: this.showAllFIs ? null : this.fiLimit
      }).then(() => {
        if (this.showAllFIs) {
          this.setFILimit(this.clientInstitutions.length)
        }

        this.$wait.end('loadingVendorInstitutions')
      })
    },
    selectVendor(vendors) {
      let selectedVendor = this.vendorProducts.find(
        v =>
          v.vendor_id === vendors[0].vendor_id &&
          v.product_id === vendors[0].product_id
      )
      this.setClientInstitutions(new Array())
      this.setSelectedVendor(selectedVendor)
      this.loadVendorInstitutions()
    },
    formatLink(url) {
      return formatUrl(url)
    },
    setAllVendors() {
      this.setVendorLimit(this.vendorProducts.length)
      this.vendorLimitTmp = this.vendorLimit
    },
    updateVendors() {
      this.setVendorLimit(parseInt(this.vendorLimitTmp))
      this.setShowAllVendors(false)
    },
    updateFIs() {
      this.setClientInstitutions(new Array())
      this.setShowAllFIs(false)
      this.loadVendorInstitutions()
    },
    fetchAllFIs() {
      this.setClientInstitutions(new Array())
      this.setShowAllFIs(true)
      this.loadVendorInstitutions()
    },
    selectedVendorClass(item, type) {
      if (type === 'row' && this.selectedVendor) {
        return item.vendor_id === this.selectedVendor.vendor_id &&
          item.product_id === this.selectedVendor.product_id
          ? 'selected-vendor'
          : ''
      }
    }
  },
  watch: {}
}
</script>
<style lang="scss" scoped>
.rankings,
.vendors {
  .title-header {
    line-height: 1;
  }
}

.show-all-link {
}

.vendor-limit,
.fi-limit {
  border-right: solid 1px #c2cfd6;

  .vendor-control,
  .fi-control {
    span {
      background-color: #20a8d8;
      color: white;
    }
  }
}
</style>

<style lang="scss">
.rankings {
  table {
    tr {
      .current-vendor-dot {
        display: inline-block;
        background-color: #000000;
        height: 10px;
        width: 10px;
        border-radius: 50%;
      }

      .empty-dot {
        display: inline-block;
        width: 10px;
      }

      &.selected-vendor {
        font-weight: bold;
        color: white;
        background-color: #20a8d8 !important;

        .current-vendor-dot {
          background-color: #ffffff;
        }

        &:hover {
          td {
            color: white !important;
          }
        }
      }
    }
  }
}
</style>
