<template>
  <PageCardWithInstitution
    pageName="FIN VendorFit"
    :logoUrl="fiLogoURL"
    :institutionId="selectedInstitution.id"
    :institutionName="selectedInstitution.name || ''"
    :iconClass="['cuis-puzzle-piece', 'card-icon-font']"
    :pageClasses="['page-card', 'vendor-fit']"
    :cardBodyClasses="['vendor-fit-predictions']"
  >
    <template slot="page_content">
      <b-row class="custom-profile-tabs prediction-categories">
        <b-col sm="12">
          <v-wait for="loadingPredictions">
            <template slot="waiting">
              <report-placeholder :progress="100"></report-placeholder>
            </template>
            <b-card class="no-hover-highlt" body-class="p-0">
              <div class="text-right pb-2 download-icon-tray">
                <v-wait for="preparingPrint">
                  <template slot="waiting">
                    <i class="fa fa-lg px-2 fa-spinner fa-spin"></i>
                  </template>
                  <i
                    @click="printProfile"
                    title="Print Profile"
                    class="cuis-print card-icon-font font-2xl px-1 xlsx-link cursor-pointer"
                  ></i>
                </v-wait>
                <v-wait for="downloadingReport">
                  <template slot="waiting">
                    <i class="fa fa-lg px-2 fa-spinner fa-spin"></i>
                  </template>
                  <i
                    class="cuis-file-excel card-icon-font font-2xl pr-2 cursor-pointer xlsx-link"
                    title="Download xlsx"
                    @click="downloadXlsx"
                  ></i>
                </v-wait>
              </div>
              <div role="tablist">
                <b-card
                  no-body
                  v-for="(section, index) in enabledSections"
                  :class="[
                    selectedSection.name === section.name ? 'active-card' : '',
                    'mb-1',
                    'std-border'
                  ]"
                  v-bind:key="index"
                >
                  <section-info :section="section"></section-info>

                  <b-card-header header-tag="header" class="p-0" role="tab">
                    <b-btn block href="#" v-b-toggle="'accordion_' + index">
                      <b-row>
                        <b-col cols="10">
                          <div class="d-flex align-items-center">
                            <div>
                              <span
                                :class="[
                                  'pr-1',
                                  'card-icon-font',
                                  sectionIconMapper[section.name]
                                ]"
                              ></span>
                            </div>
                            <div>
                              <h6 class="mb-0">
                                <b>{{ sectionTitle(section.name) }}</b>
                              </h6>
                            </div>
                          </div>
                        </b-col>
                        <b-col cols="2" class="text-right">
                          <i
                            :id="`${section.name.replace(/\W/g, '-')}-info`"
                            class="icon-info icons font-weight-bold"
                            aria-hidden="true"
                            v-if="section.show_info"
                            v-html="'&nbsp;&nbsp;'"
                          ></i>
                          <i
                            class="fa fa-lg fa-caret-down"
                            aria-hidden="true"
                          ></i>
                        </b-col>
                      </b-row>
                    </b-btn>
                  </b-card-header>
                  <b-collapse
                    accordion="my-accordion"
                    role="tabpanel"
                    v-bind:id="'accordion_' + index"
                    @shown="setSelectedSection(section)"
                    :visible="selectedSection === section"
                  >
                    <b-card-body>
                      <keep-alive>
                        <component
                          v-bind:is="componentName(section.name)"
                          ref="fitProfileSection"
                        ></component>
                      </keep-alive>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </div>
            </b-card>
          </v-wait>
        </b-col>
        <vendors-modal @vendorUpdated="vendorUpdated"></vendors-modal>
      </b-row>

      <div class="vendor-fit-pdf">
        <div class="report-header pb-3">
          <div class="row">
            <div class="col-sm-6">
              <img src="/logo.png" class="report-logo" />
            </div>
            <div class="col-sm-6 text-right align-self-end">
              <div class=" d-flex justify-content-end">
                <header-logo
                  logoType="fi"
                  :logoUrl="fiLogoURL"
                  :logoId="selectedInstitution.id"
                ></header-logo>
                <h5 class="pt-1  text-right">{{ selectedInstitution.name }}</h5>
              </div>
            </div>
          </div>
        </div>
        <b-card
          no-body
          :class="['mb-1', 'std-border', 'fit-category']"
          v-for="(section, index) in enabledSections"
          v-bind:key="index"
        >
          <template slot="header">
            <b-row>
              <b-col cols="12">
                <div class="d-flex align-items-center">
                  <div>
                    <span
                      :class="[
                        'pr-1',
                        'card-icon-font',
                        sectionIconMapper[section.name]
                      ]"
                    ></span>
                  </div>
                  <div>
                    <h6 class="mb-0">
                      <b>{{ sectionTitle(section.name) }}</b>
                    </h6>
                  </div>
                </div>
              </b-col>
            </b-row>
          </template>
          <b-card-body>
            <component
              v-bind:is="componentName(section.name)"
              :renderedForPDF="true"
              ref="fitProfileSection"
            ></component>
          </b-card-body>
        </b-card>
      </div>
    </template>
  </PageCardWithInstitution>
</template>
<script charset="utf-8">
// global
import Vue from 'vue'
import router from '@/router'
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import store from '@/store/store'
// api
import fitProfileAPI from '@/api/finapps/fit_profile'
// layout
import PageCardWithInstitution from '@/modules/core/components/layouts/PageCardWithInstitution'
// ui components
import Risk from '../components/Risk'
import Size from '../components/Size'
import Profit from '../components/Profit'
import Growth from '../components/Growth'
import Market from '../components/Market'
import Strategy from '../components/Strategy'
import Technology from '../components/Technology'
import Productivity from '../components/Productivity'
import ProductFitRanking from '../components/ProductFitRanking'
import FinancialInstitution from '../components/FinancialInstitution'

import SectionInfo from '../components/SectionInfo'
import VendorsModal from '../components/VendorsModal'
import ReportPlaceholder from '../components/ReportGeneratorPlaceholder'
import HeaderLogo from '@/modules/core/components/HeaderLogo'

export default {
  name: 'Predictions',
  components: {
    Risk,
    Size,
    Profit,
    Growth,
    Market,
    Strategy,
    Technology,
    Productivity,
    ProductFitRanking,
    ReportPlaceholder,
    FinancialInstitution,
    SectionInfo,
    VendorsModal,
    PageCardWithInstitution,
    HeaderLogo
  },
  beforeRouteEnter(to, from, next) {
    if (
      store.getters['VendorFit/selectedInstitution'].id &&
      store.getters['VendorFit/selectedSolution'].id
    ) {
      next()
    } else {
      Vue.toasted.show('Please select institution.', {
        icon: 'chain-broken',
        type: 'error'
      })
      router.push('/vendor_fit/select_institution')
    }
  },
  mounted() {},
  data() {
    return {
      selectedSection: {}
    }
  },
  created() {
    this.setVendorProducts([])
    this.setClientInstitutions([])

    this.loadInitData()
  },
  computed: {
    ...mapState('VendorFit', {
      enabledSections: state => state.sections,
      vendorProducts: state => state.vendorProducts,
      selectedVendor: state => state.selectedVendor,
      selectedSolution: state => state.selectedSolution,
      selectedInstitution: state => state.selectedInstitution
    }),
    ...mapGetters('VendorFit', ['fiLogoURL']),
    sectionIconMapper() {
      return {}
    },
    categorySections() {
      return this.enabledSections
        .filter(sec => sec.type === 'category')
        .map(section => section.name)
    }
  },
  methods: {
    ...mapActions('VendorFit', [
      'fetchProfileDetail',
      'fetchSolutionDetail',
      'fetchVendorProducts',
      'fetchClientInstitutions'
    ]),
    ...mapMutations('VendorFit', [
      'setProfileElements',
      'setSelectedVendor',
      'setVendorProducts',
      'setClientInstitutions',
      'resetVendorDependentData'
    ]),
    loadInitData() {
      this.$wait.start('loadingPredictions')

      Promise.all([
        this.loadProfileDetail(),
        this.loadSolutionDetail(),
        this.loadVendorAndInstitutions()
      ]).then(() => {
        this.$wait.end('loadingPredictions')

        this.$nextTick(() => {
          this.setSelectedSection(this.enabledSections[0])
        })
      })
    },
    loadProfileDetail() {
      return this.fetchProfileDetail({ fiID: this.selectedInstitution.id })
    },
    loadSolutionDetail() {
      return this.fetchSolutionDetail({
        fiID: this.selectedInstitution.id,
        solutionID: this.selectedSolution.id
      })
    },
    async loadVendorAndInstitutions() {
      await this.fetchVendorProducts({
        fiID: this.selectedInstitution.id,
        limit: null
      })

      this.setSelectedVendor(this.vendorProducts[0])

      return this.fetchClientInstitutions({
        productID: this.selectedVendor.product_id,
        limit: 20
      })
    },
    sectionTitle(sectionName) {
      // if needed can customize section names here
      return sectionName
    },
    componentName(sectionName) {
      return sectionName.replace(/\W/g, '')
    },
    manageSection() {},
    setSelectedSection(section) {
      this.selectedSection = section
      this.selectedChildComponent().configChanged()
    },
    selectedChildComponent() {
      if (this.selectedSection) {
        return this.$refs.fitProfileSection.find(
          section => section.$data.sectionName === this.selectedSection.name
        )
      }
    },
    vendorUpdated() {
      this.resetVendorDependentData()
      this.selectedChildComponent().configChanged()
    },
    printProfile() {
      this.$wait.start('preparingPrint')

      this.$toasted.show('Preparing data, please wait ...', {
        icon: 'print',
        type: 'success'
      })

      let contentsToLoad = [
        this.$store.dispatch('VendorFit/fetchProfileDetail', {
          fiID: this.selectedInstitution.id
        }),
        this.$store.dispatch('VendorFit/fetchSolutionDetail', {
          fiID: this.selectedInstitution.id,
          solutionID: this.selectedSolution.id
        }),
        this.$store.dispatch('VendorFit/fetchVendorProducts', {
          fiID: this.selectedInstitution.id,
          limit: null
        }),
        this.$store.dispatch('VendorFit/fetchAllInstitutions', {
          productID: this.selectedVendor.product_id,
          limit: null
        })
      ]

      this.categorySections.forEach(category => {
        contentsToLoad.push(
          this.$store.dispatch('VendorFit/fetchCategoryElements', {
            fiID: this.selectedInstitution.id,
            solutionID: this.selectedSolution.id,
            productID: this.selectedVendor.product_id,
            category: category.toLowerCase()
          })
        )
      })

      Promise.all(contentsToLoad).then(() => {
        this.$nextTick(() => {
          setTimeout(
            function() {
              this.$nextTick(() => {
                this.$toasted.clear()
                window.print()
                this.$wait.end('preparingPrint')
              })
            }.bind(this),
            0
          )
        })
      })
    },
    downloadXlsx() {
      let params = {
        fiID: this.selectedInstitution.id,
        solutionID: this.selectedSolution.id,
        productID: this.selectedVendor.product_id,
        vendorID: this.selectedVendor.vendor_id
      }

      this.$wait.start('downloadingReport')
      this.$toasted.show('Started downloading VendorFit', {
        icon: 'print',
        type: 'success'
      })

      fitProfileAPI
        .xlsxDownload(params)
        .then(() => {
          this.$toasted.show('VendorFit successfully downloaded', {
            icon: 'print',
            type: 'success'
          })
        })
        .finally(() => {
          this.$wait.end('downloadingReport')
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.vendor-fit-predictions {
  .download-icon-tray {
    div {
      display: inline;
    }
  }

  .btn {
    background: #f1f3f5;
    text-align: left;
    margin-top: 0;
    border: none;
  }

  .card {
    a.btn-secondary:focus {
      box-shadow: none;
    }
  }

  .card:not(.active-card) {
    &:hover {
      box-shadow: 0 0 0 0.1rem rgba(164, 183, 193, 0.5);
    }
  }

  .vendor-fit-pdf {
    display: none;
  }
}
</style>

<style lang="scss">
@media print {
  .page-card.vendor-fit {
    .page-card-with-logo {
      display: none;
    }

    .vendor-fit-predictions {
      .prediction-categories {
        display: none;
      }

      .vendor-fit-pdf {
        display: block;

        .fit-category {
          page-break-after: always;

          .ranking-card {
            page-break-inside: avoid;

            .paginators {
              display: none;
            }

            table {
              margin-top: 1rem;
              margin-bottom: 2rem;

              th {
                break-inside: avoid;
                padding: 0.3rem;
              }

              td {
                padding: 0.3rem;
              }
            }

            .rankings {
              table {
                th {
                  background-image: none;
                }

                tr {
                  &.selected-vendor {
                    color: initial;
                    background-color: white !important;

                    .current-vendor-dot {
                      background-color: #000000;
                    }
                  }
                }
              }
            }

            .vendors {
              table {
                table-layout: fixed;

                th {
                  background-image: none;

                  &[aria-colindex='1'] {
                    width: 14%;
                  }
                  &[aria-colindex='2'] {
                    width: 30%;
                  }
                  &[aria-colindex='3'] {
                    width: 25%;
                  }
                  &[aria-colindex='4'] {
                    width: 10%;
                  }
                  &[aria-colindex='5'] {
                    width: 10%;
                  }
                  &[aria-colindex='6'] {
                    width: 10%;
                  }
                }
              }
            }
          }

          .category-card {
            .card-body {
              padding-left: 0.5rem;
              padding-right: 0.5rem;

              .category-element {
                padding-left: 0.5rem;
                padding-right: 0.5rem;
              }
            }
          }
        }
      }
    }
  }
}
</style>
