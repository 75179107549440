<template>
  <b-popover
    :target="`${section.name.replace(/\W/g, '-')}-info`"
    triggers="hover"
    placement="left"
    v-if="section"
  >
    <template v-slot:title>
      <div class="popover-info-header">
        <h6 class="mb-1">
          <b>{{ section.name }}</b>
        </h6>
      </div>
    </template>
    <div class="popover-content">
      <div v-if="section.name === 'Product Fit Ranking'">
        <p>
          The Product Fit Ranking leverages a comprehensive array of data
          (independent variables) to gauge the fit of any financial institution
          with specific core vendor-product combinations. The regression
          analysis integrates over 100 independent variables or data points from
          data sets relating to the institution’s size, market, competition,
          profitability, growth, risk, retail offering, business offering,
          strategy, productivity and other factors to identify better solution
          matches. To qualify as independent variables, the utilized data must
          not be influenced by core integration and for that reason non-core
          vendor-products relationships (ex. retail online banking) are not
          utilized but whether an institution offers certain services is
          utilized. The resulting Product Fit scores sum to 100 percent as a
          form of relative confidence on a set of vendor-product solutions. The
          independent variables can be thought of as measures of relative
          pressures on the institution that could be external (urban market with
          younger demographics) or internal (best-of-breed product affinity).
          Applications for the Product Fit Ranking include identification of
          at-risk core clients or conversely core conversion prospects that have
          a lower perceived fit with their current solution. The lower an
          institution’s product fit score is with their incumbent vendor, the
          higher the risk of conversion.
        </p>
      </div>
      <div v-if="section.name === 'Size'">
        <p>
          The client base of any selected vendor-product can be profiled to show
          patterns of client segmentation based on institution size, market
          area, strategy, profitability, growth, risk, and productivity. The
          graphical display of metrics relating to these areas allows the user
          to visualize segments where the vendor-product has higher or lower
          resonance, or fit. The metrics in the Size folder gauge relative size
          of the client institutions and include total assets, branch count,
          account volume and other similar metrics.
        </p>
      </div>
      <div v-if="section.name === 'Market'">
        <p>
          The client base of any selected vendor-product can be profiled to show
          patterns of client segmentation based on institution size, market
          area, strategy, profitability, growth, risk, and productivity. The
          graphical display of metrics relating to these areas allows the user
          to visualize segments where the vendor-product has higher or lower
          resonance, or fit. The metrics in the Market folder examine the market
          characteristics of client footprints and include market size,
          geographic regions, market coverage, market rank and other similar
          metrics.
        </p>
      </div>
      <div v-if="section.name === 'Strategy'">
        <p>
          The client base of any selected vendor-product can be profiled to show
          patterns of client segmentation based on institution size, market
          area, strategy, profitability, growth, risk, and productivity. The
          graphical display of metrics relating to these areas allows the user
          to visualize segments where the vendor-product has higher or lower
          resonance, or fit. The metrics in the Strategy folder examine the
          market strategy of client institutions and include top loan category,
          loan volume, average deposit balances and other similar metrics.
        </p>
      </div>
      <div v-if="section.name === 'Profit'">
        <p>
          The client base of any selected vendor-product can be profiled to show
          patterns of client segmentation based on institution size, market
          area, strategy, profitability, growth, risk, and productivity. The
          graphical display of metrics relating to these areas allows the user
          to visualize segments where the vendor-product has higher or lower
          resonance, or fit. The metrics in the Profit folder examine the
          relative profitability of client institutions and include return on
          assets, non-interest income levels, overhead expense and other similar
          metrics.
        </p>
      </div>
      <div v-if="section.name === 'Growth'">
        <p>
          The client base of any selected vendor-product can be profiled to show
          patterns of client segmentation based on institution size, market
          area, strategy, profitability, growth, risk, and productivity. The
          graphical display of metrics relating to these areas allows the user
          to visualize segments where the vendor-product has higher or lower
          resonance, or fit. The metrics in the Growth folder examine the
          relative growth of client institutions and include loan & lease
          growth, account growth, revenue growth, expense growth and other
          similar metrics.
        </p>
      </div>
      <div v-if="section.name === 'Risk'">
        <p>
          The client base of any selected vendor-product can be profiled to show
          patterns of client segmentation based on institution size, market
          area, strategy, profitability, growth, risk, and productivity. The
          graphical display of metrics relating to these areas allows the user
          to visualize segments where the vendor-product has higher or lower
          resonance, or fit. The metrics in the Risk folder examine the relative
          risks of client institutions and include the Texas ratio,
          loan-to-deposit ratio and other similar metrics.
        </p>
      </div>
      <div v-if="section.name === 'Productivity'">
        <p>
          The client base of any selected vendor-product can be profiled to show
          patterns of client segmentation based on institution size, market
          area, strategy, profitability, growth, risk, and productivity. The
          graphical display of metrics relating to these areas allows the user
          to visualize segments where the vendor-product has higher or lower
          resonance, or fit. The metrics in the Productivity folder examine the
          relative productivity of client institutions and include the
          efficiency ratio, staff production (revenues per FTE), branch capacity
          (core deposits per office) and other similar metrics.
        </p>
      </div>
      <div v-if="section.name === 'Technology'">
        <p>
          The client base of any selected vendor-product can be profiled to show
          patterns of client segmentation based on institution size, market
          area, strategy, profitability, growth, risk, and productivity. The
          graphical display of metrics relating to these areas allows the user
          to visualize segments where the vendor-product has higher or lower
          resonance, or fit. The metrics in the Technology folder examine the
          technology characteristics of client institutions and include vendor
          diversity, retail services innovation, customer enrollment levels and
          other similar metrics.
        </p>
      </div>
    </div>
  </b-popover>
</template>

<script>
export default {
  name: 'SectionInfo',
  props: {
    section: {
      type: Object,
      required: true
    }
  }
}
</script>
