<template>
  <div>
    <div class="">
      <b-card
        :class="pageClasses"
        :header-class="cardHeaderClasses"
        :body-class="cardBodyClasses"
      >
        <div slot="header">
          <b-row class="fit-to-center">
            <b-col cols="6">
              <div class="page-header-left">
                <div class="pr-1 header-icon"><i :class="cardIcon"></i></div>
                <div>
                  <h5 class="mb-0">
                    <b>{{ pageName }}</b>
                    <span v-if="pageType">
                      - <i>{{ pageType }}</i></span
                    >
                  </h5>
                </div>
              </div>
            </b-col>
            <b-col cols="6">
              <div class="page-header-right">
                <header-logo
                  logoType="fi"
                  :logoUrl="logoUrl"
                  :logoId="institutionId"
                ></header-logo>
                <div>
                  <h5 class="mb-0">{{ institutionName }}</h5>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>

        <slot name="page_content"></slot>
      </b-card>
    </div>
  </div>
</template>

<script>
import HeaderLogo from '@/modules/core/components/HeaderLogo'

export default {
  name: 'LayoutPageCardWithInstitution',
  components: {
    HeaderLogo
  },
  props: {
    pageName: {
      type: String,
      required: true,
      default: '-'
    },
    pageType: {
      type: String,
      required: false
    },
    institutionId: {
      type: Number,
      required: true
    },
    institutionName: {
      type: String,
      required: true
    },
    logoUrl: {
      type: String,
      required: true
    },
    pageClasses: {
      type: Array,
      required: false,
      default() {
        return ['page-card']
      }
    },
    cardHeaderClasses: {
      type: Array,
      required: false,
      default() {
        return ['page-card-with-logo']
      }
    },
    cardBodyClasses: {
      type: Array,
      required: false
    },
    iconClass: {
      type: Array,
      required: false
    }
  },
  computed: {
    cardIcon() {
      return this.iconClass ? this.iconClass : ['fa', 'fa-bar-chart', 'fa-lg']
    }
  }
}
</script>

<style lang="scss"></style>
