var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PageCardWithInstitution',{attrs:{"pageName":"FIN VendorFit","logoUrl":_vm.fiLogoURL,"institutionId":_vm.selectedInstitution.id,"institutionName":_vm.selectedInstitution.name || '',"iconClass":['cuis-puzzle-piece', 'card-icon-font'],"pageClasses":['page-card', 'vendor-fit'],"cardBodyClasses":['vendor-fit-predictions']}},[_c('template',{slot:"page_content"},[_c('b-row',{staticClass:"custom-profile-tabs prediction-categories"},[_c('b-col',{attrs:{"sm":"12"}},[_c('v-wait',{attrs:{"for":"loadingPredictions"}},[_c('template',{slot:"waiting"},[_c('report-placeholder',{attrs:{"progress":100}})],1),_c('b-card',{staticClass:"no-hover-highlt",attrs:{"body-class":"p-0"}},[_c('div',{staticClass:"text-right pb-2 download-icon-tray"},[_c('v-wait',{attrs:{"for":"preparingPrint"}},[_c('template',{slot:"waiting"},[_c('i',{staticClass:"fa fa-lg px-2 fa-spinner fa-spin"})]),_c('i',{staticClass:"cuis-print card-icon-font font-2xl px-1 xlsx-link cursor-pointer",attrs:{"title":"Print Profile"},on:{"click":_vm.printProfile}})],2),_c('v-wait',{attrs:{"for":"downloadingReport"}},[_c('template',{slot:"waiting"},[_c('i',{staticClass:"fa fa-lg px-2 fa-spinner fa-spin"})]),_c('i',{staticClass:"cuis-file-excel card-icon-font font-2xl pr-2 cursor-pointer xlsx-link",attrs:{"title":"Download xlsx"},on:{"click":_vm.downloadXlsx}})],2)],1),_c('div',{attrs:{"role":"tablist"}},_vm._l((_vm.enabledSections),function(section,index){return _c('b-card',{key:index,class:[
                  _vm.selectedSection.name === section.name ? 'active-card' : '',
                  'mb-1',
                  'std-border'
                ],attrs:{"no-body":""}},[_c('section-info',{attrs:{"section":section}}),_c('b-card-header',{staticClass:"p-0",attrs:{"header-tag":"header","role":"tab"}},[_c('b-btn',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:('accordion_' + index),expression:"'accordion_' + index"}],attrs:{"block":"","href":"#"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"10"}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',[_c('span',{class:[
                                'pr-1',
                                'card-icon-font',
                                _vm.sectionIconMapper[section.name]
                              ]})]),_c('div',[_c('h6',{staticClass:"mb-0"},[_c('b',[_vm._v(_vm._s(_vm.sectionTitle(section.name)))])])])])]),_c('b-col',{staticClass:"text-right",attrs:{"cols":"2"}},[(section.show_info)?_c('i',{staticClass:"icon-info icons font-weight-bold",attrs:{"id":((section.name.replace(/\W/g, '-')) + "-info"),"aria-hidden":"true"},domProps:{"innerHTML":_vm._s('&nbsp;&nbsp;')}}):_vm._e(),_c('i',{staticClass:"fa fa-lg fa-caret-down",attrs:{"aria-hidden":"true"}})])],1)],1)],1),_c('b-collapse',{attrs:{"accordion":"my-accordion","role":"tabpanel","id":'accordion_' + index,"visible":_vm.selectedSection === section},on:{"shown":function($event){return _vm.setSelectedSection(section)}}},[_c('b-card-body',[_c('keep-alive',[_c(_vm.componentName(section.name),{ref:"fitProfileSection",refInFor:true,tag:"component"})],1)],1)],1)],1)}),1)])],2)],1),_c('vendors-modal',{on:{"vendorUpdated":_vm.vendorUpdated}})],1),_c('div',{staticClass:"vendor-fit-pdf"},[_c('div',{staticClass:"report-header pb-3"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6"},[_c('img',{staticClass:"report-logo",attrs:{"src":"/logo.png"}})]),_c('div',{staticClass:"col-sm-6 text-right align-self-end"},[_c('div',{staticClass:" d-flex justify-content-end"},[_c('header-logo',{attrs:{"logoType":"fi","logoUrl":_vm.fiLogoURL,"logoId":_vm.selectedInstitution.id}}),_c('h5',{staticClass:"pt-1  text-right"},[_vm._v(_vm._s(_vm.selectedInstitution.name))])],1)])])]),_vm._l((_vm.enabledSections),function(section,index){return _c('b-card',{key:index,class:['mb-1', 'std-border', 'fit-category'],attrs:{"no-body":""}},[_c('template',{slot:"header"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',[_c('span',{class:[
                      'pr-1',
                      'card-icon-font',
                      _vm.sectionIconMapper[section.name]
                    ]})]),_c('div',[_c('h6',{staticClass:"mb-0"},[_c('b',[_vm._v(_vm._s(_vm.sectionTitle(section.name)))])])])])])],1)],1),_c('b-card-body',[_c(_vm.componentName(section.name),{ref:"fitProfileSection",refInFor:true,tag:"component",attrs:{"renderedForPDF":true}})],1)],2)})],2)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }