<template>
  <b-modal
    id="vendorsModal"
    ref="vendorsModal"
    size="lg"
    :ok-only="true"
    ok-title="Close"
    no-close-on-esc
  >
    <template slot="modal-header">
      <div class="row pp-modal-title">
        <div class="col-sm-10">
          <h5 class="mb-0">Select Vendor</h5>
        </div>
      </div>
    </template>

    <b-table striped hover :items="vendorProducts" :fields="vendorProductFields">
      <template #cell(confidence)="data">
        {{ data.item.confidence }}%
      </template>
      <template #cell(actions)="data">
        <span>
          <b-button
            size="sm"
            variant="primary"
            @click="selectVendor(data.item)"
            class="px-2"
            >Select</b-button
          >
        </span>
      </template>
    </b-table>
  </b-modal>
</template>

<script>
// global
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'VendorsModal',
  props: {
  },
  data() {
    return {}
  },
  computed: {
    ...mapState('VendorFit', {
      vendorProducts: state => state.vendorProducts
    }),
    vendorProductFields() {
      return [
        { key: 'vendor_name', label: 'Vendor', sortable: true },
        { key: 'product_name', label: 'Product', sortable: true },
        { key: 'confidence', label: 'Fit %', sortable: true },
        { key: 'actions', label: '', sortable: false }
      ]
    }
  },
  methods: {
    ...mapMutations('VendorFit', ['setSelectedVendor']),
    selectVendor(vendor) {
      let selectedVendor = this.vendorProducts.find(
        v =>
          v.vendor_id === vendor.vendor_id && v.product_id === vendor.product_id
      )
      this.setSelectedVendor(selectedVendor)
      this.$emit('vendorUpdated')
      this.$bvModal.hide('vendorsModal')
    }
  }
}
</script>

<style lang="scss" scoped>
.pp-modal-title {
  width: 100%;
}
</style>
