<template>
  <div class="category-fit">
    <div class="row">
      <div class="col-sm-12" v-if="selectedVendor">
        <div class="row">
          <div class="col-sm-6 pl-2">
            <div class="d-flex">
              <header-logo
                logoType="fi"
                :logoUrl="fiLogoURL"
                :logoId="selectedInstitution.id"
                class="justify-content-center mb-2"
              ></header-logo>
              <div class="pl-0">
                <h6 class="mb-0">
                  {{ profileDetail.name }}, {{ profileDetail.city }},
                  {{ profileDetail.state }}
                </h6>
                <div>
                  <span
                    class="site-link"
                    v-if="
                      profileDetail.website_url &&
                        profileDetail.website_url !== '0'
                    "
                  >
                    <a
                      target="_blank"
                      :href="formatLink(profileDetail.website_url)"
                    >
                      {{ profileDetail.website_url }}
                      <sup>
                        <i class="fa fa-external-link" aria-hidden="true"></i>
                      </sup>
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-6 pl-0">
            <div class="d-flex justify-content-between">
              <div class="d-flex">
                <header-logo
                  logoType="vendor"
                  :logoUrl="vendorLogoURL"
                  :logoId="selectedVendor.vendor_id"
                  class="justify-content-center mb-2"
                ></header-logo>
                <div class="pl-0">
                  <div class="d-flex">
                    <h6 class="mb-0">
                      {{ selectedVendor.vendor_name }} |
                      {{ selectedVendor.product_name }}
                    </h6>
                  </div>
                  <div>
                    <span
                      class="site-link"
                      v-if="
                        selectedVendor.vendor_url &&
                          selectedVendor.vendor_url !== '0'
                      "
                    >
                      <a
                        target="_blank"
                        :href="formatLink(selectedVendor.vendor_url)"
                      >
                        {{ selectedVendor.vendor_url }}
                        <sup>
                          <i class="fa fa-external-link" aria-hidden="true"></i>
                        </sup>
                      </a>
                    </span>
                  </div>
                </div>
              </div>
              <div class="text-center pr-2">
                <h6 class="mb-0 font-weight-bold">
                  {{ selectedVendor.no_of_banks }}
                </h6>
                <h6 class="mb-0 font-weight-bold">Client Institutions</h6>
              </div>
            </div>
          </div>
        </div>
        <hr class="m-1 mb-2" />
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 text-right">
        <div @click="changeVendor()" class="px-2 look-as-a-tag">
          <h6 class="">Change Vendor</h6>
        </div>
      </div>
    </div>
    <div class="mt-3">
      <v-wait for="loadingElements">
        <template slot="waiting">
          <content-placeholders :rounded="true" class="loader">
            <content-placeholders-heading />
            <content-placeholders-text :lines="10"></content-placeholders-text>
          </content-placeholders>
        </template>

        <b-card-group
          deck
          :class="[categoryChunk.length === 2 ? 'w-100' : 'w-50', ' mb-3']"
          v-for="(categoryChunk, index) in categoryElementChunks"
          :key="index"
        >
          <b-card
            header-class="p-2"
            body-class="pb-1"
            footer-class="px-0"
            class="std-border no-hover-highlt category-card mr-0"
            v-for="(element, index) in categoryChunk"
            :key="index"
          >
            <template #header>
              <h6 class="mb-0 font-weight-bold">
                {{ element.element.title }}
                <i
                  class="icon-info icons px-1"
                  :id="`category-element-${category}-${element.element.id}`"
                ></i>
              </h6>
            </template>
            <template #footer>
              <div class="row">
                <div class="col-sm-12">
                  <div class="d-flex justify-content-center legend">
                    <div class="d-flex align-items-center pr-2">
                      <div class="dot subject-fi-dot ml-0"></div>
                      <div class="px-2">
                        <div class="mb-0">
                          Subject FI :
                        </div>
                      </div>
                      <div>
                        {{ element.subject_fi }}
                      </div>
                    </div>
                    <div
                      class="d-flex align-items-center pl-3"
                      v-if="element.client_value"
                    >
                      <div class="dot client-median-dot ml-0"></div>
                      <div class="px-2">
                        <div class="mb-0">
                          Client
                          {{
                            element.element.vendor_value === 'max'
                              ? 'Max'
                              : 'Median'
                          }}
                          :
                        </div>
                      </div>
                      <div>
                        {{ element.client_value }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <b-popover
              :target="`category-element-${category}-${element.element.id}`"
              triggers="hover"
            >
              <p class="popover-content mb-0">
                {{ element.element.description }}
              </p>
            </b-popover>
            <div class="row">
              <div class="col-sm-7">
                <b-list-group>
                  <b-list-group-item
                    v-for="(value, index) in element.element_values"
                    :key="index"
                    class="py-1 category-element"
                  >
                    <div
                      class="d-flex justify-content-between align-self-center"
                    >
                      <div class="category-element-name">
                        {{ value.element || 'NA' }}
                      </div>
                      <div
                        v-if="value.subject_fi && value.client_median"
                        class="intersection-container"
                      >
                        <div class="circle fi-circle"></div>
                        <div class="circle median-circle"></div>
                      </div>
                      <div
                        :class="{
                          'subject-fi-dot': value.subject_fi,
                          'client-median-dot': value.client_median,
                          'dot text-right align-self-center': true
                        }"
                        v-else
                      ></div>
                    </div>
                  </b-list-group-item>
                </b-list-group>
              </div>
              <div class="col-sm-5 pl-0">
                <fit-chart :element="element"></fit-chart>
              </div>
            </div>
          </b-card>
        </b-card-group>
      </v-wait>
    </div>
  </div>
</template>

<script charset="utf-8">
// global
import { mapState, mapGetters, mapActions } from 'vuex'
// ui components
import FitChart from './FitChart'
import HeaderLogo from '@/modules/core/components/HeaderLogo'
// utils
import _ from 'lodash'
import { formatUrl } from '@/utilities'

export default {
  name: 'CategoryFit',
  props: {
    category: {
      type: String,
      required: true
    }
  },
  components: {
    FitChart,
    HeaderLogo
  },
  data() {
    return {
      elements: []
    }
  },
  created() {},
  computed: {
    ...mapState('VendorFit', {
      profileDetail: state => state.profileDetail,
      selectedVendor: state => state.selectedVendor,
      selectedSolution: state => state.selectedSolution,
      selectedInstitution: state => state.selectedInstitution,
      sizeElements: state => state.sizeElements,
      marketElements: state => state.marketElements,
      strategyElements: state => state.strategyElements,
      profitElements: state => state.profitElements,
      growthElements: state => state.growthElements,
      riskElements: state => state.riskElements,
      technologyElements: state => state.technologyElements,
      productivityElements: state => state.productivityElements
    }),
    ...mapGetters('VendorFit', ['fiLogoURL', 'vendorLogoURL']),
    categoryElements() {
      return this[`${this.category}Elements`]
    },
    categoryElementChunks() {
      return _.chunk(this.categoryElements, 2)
    }
  },
  methods: {
    ...mapActions('VendorFit', ['fetchCategoryElements']),
    loadData() {
      this.loadElementValues()
    },
    loadElementValues() {
      this.$wait.start('loadingElements')

      this.fetchCategoryElements({
        fiID: this.selectedInstitution.id,
        solutionID: this.selectedSolution.id,
        productID: this.selectedVendor.product_id,
        category: this.category
      }).then(() => {
        this.$wait.end('loadingElements')
      })
    },
    formatLink(url) {
      return formatUrl(url)
    },
    changeVendor() {
      this.$bvModal.show('vendorsModal')
    }
  }
}
</script>

<style lang="scss" scoped>
.category-fit {
  .dot {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    display: inline-block;

    &.subject-fi-dot {
      background-color: #414142;
      margin-left: -6px;
    }

    &.client-median-dot {
      background-color: #a7ca77;
      margin-left: -6px;
    }
  }

  .mini-dot {
    height: 12px;
    width: 12px;
    border-radius: 1%;
    display: inline-block;

    &.subject-fi-dot {
      background-color: #414142;
      margin-left: -6px;
    }

    &.client-median-dot {
      background-color: #a7ca77;
      margin-left: -6px;
    }
  }

  .circle {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    position: absolute;

    &.median-circle {
      margin-left: -6px;
      border: solid 1px #a7ca77;
      background-color: rgba(167, 202, 119, 0.9);
    }

    &.fi-circle {
      margin-left: 0px;
      border: solid 1px #414142;
      background-color: rgba(65, 65, 66, 1);
    }
  }

  .intersection-container {
    margin-right: 12px;
    padding-top: 0.2rem;
  }

  .category-card {
    .card-footer {
      background-color: #ffffff;

      .legend {
        flex-wrap: wrap;
      }
    }
  }

  .category-element-name {
    width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
