<template>
  <v-wait for="fetchProfileDetails">
    <template slot="waiting">
      <content-placeholders :rounded="true">
        <content-placeholders-text :lines="7"></content-placeholders-text>
      </content-placeholders>
    </template>
    <div class="row">
      <div class="col-sm-8">
        <h5>
          <b
            >{{ profileDetail.name }}, {{ profileDetail.city }},
            {{ profileDetail.state }}</b
          >
        </h5>
      </div>
      <div class="col-sm-4 text-right">
        <span
          class="site-link"
          v-if="profileDetail.website_url && profileDetail.website_url !== '0'"
        >
          <a target="_blank" :href="formatLink(profileDetail.website_url)">
            {{ profileDetail.website_url }}
            <sup>
              <i class="fa fa-external-link" aria-hidden="true"></i>
            </sup>
          </a>
        </span>
      </div>
    </div>

    <div class="row p-2">
      <div class="col-sm-12">
        <div class="row">
          <div class="col-sm-6">
            <div class="row mb-3">
              <div class="col-sm-12">
                <div class="row">
                  <div class="col-sm-12">
                    <h6 class="mb-1"><b>FI Summary</b></h6>
                  </div>
                </div>
                <table>
                  <tr>
                    <td class="pr-4">Total Assets:</td>
                    <td>{{ profileDetail.assets }}</td>
                  </tr>
                  <tr>
                    <td class="pr-4">Branches:</td>
                    <td>{{ profileDetail.branches }}</td>
                  </tr>
                  <tr>
                    <td class="pr-4">Market Coverage:</td>
                    <td>{{ profileDetail.market_coverage }}</td>
                  </tr>
                  <tr v-if="profileDetail.fi_type !== 'credit_union'">
                    <td class="pr-4">
                      {{
                        profileDetail.fi_type === 'bhc'
                          ? 'Subs:'
                          : 'Holding Company:'
                      }}
                    </td>
                    <td>{{ profileDetail.bhc }}</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>

          <div class="col-sm-6">
            <div class="row mb-3">
              <div class="col-sm-12">
                <div class="row">
                  <div class="col-sm-12">
                    <h6 class="mb-1"><b>Current Solution</b></h6>
                  </div>
                </div>
                <table>
                  <tr>
                    <td>Vendor:</td>
                    <td class="px-4">{{ solutionDetail.vendor }}</td>
                  </tr>
                  <tr>
                    <td>Product:</td>
                    <td class="px-4">{{ solutionDetail.product }}</td>
                  </tr>
                  <tr>
                    <td>Clients:</td>
                    <td class="px-4">
                      {{ solutionDetail.clients | numberFormat }}
                    </td>
                  </tr>
                  <tr>
                    <td>URL:</td>
                    <td class="px-4">
                      <span
                        class="site-link"
                        v-if="solutionDetail.url && solutionDetail.url !== '0'"
                      >
                        <a
                          target="_blank"
                          :href="formatLink(solutionDetail.url)"
                        >
                          {{ solutionDetail.url }}
                          <sup>
                            <i
                              class="fa fa-external-link"
                              aria-hidden="true"
                            ></i>
                          </sup>
                        </a>
                      </span>
                      <span v-else>NA</span>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-wait>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { formatUrl } from '../../../utilities'

export default {
  name: 'VendorFitFinancialInstitution',
  data() {
    return {
      sectionName: 'Financial Institution'
    }
  },
  props: {},
  created() {},
  computed: {
    ...mapState('VendorFit', {
      profileDetail: state => state.profileDetail,
      solutionDetail: state => state.solutionDetail,
      selectedSolution: state => state.selectedSolution,
      selectedInstitution: state => state.selectedInstitution
    })
  },
  methods: {
    ...mapActions('VendorFit', ['fetchProfileDetail', 'fetchSolutionDetail']),
    configChanged() {
      this.loadInitData()
    },
    loadInitData() {
      this.$wait.start('fetchProfileDetails')

      Promise.all([this.loadProfileDetail(), this.loadSolutionDetail()]).then(
        () => {
          this.$wait.end('fetchProfileDetails')
        }
      )
    },
    loadProfileDetail() {
      return this.fetchProfileDetail({ fiID: this.selectedInstitution.id })
    },
    loadSolutionDetail() {
      return this.fetchSolutionDetail({
        fiID: this.selectedInstitution.id,
        solutionID: this.selectedSolution.id
      })
    },
    formatLink(url) {
      return formatUrl(url)
    },
    openLink(url) {
      window.open(formatUrl(url), '_blank')
    }
  },
  watch: {}
}
</script>

<style lang="scss" scoped>
.site-link {
  font-size: 1rem;
}
</style>
