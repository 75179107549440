<template>
  <div>
    <category-fit category="market" ref="predictionsByMarket"></category-fit>
  </div>
</template>

<script charset="utf-8">
// ui components
import CategoryFit from './CategoryFit'

export default {
  name: 'Market',
  components: {
    CategoryFit
  },
  data() {
    return {
      sectionName: 'Market'
    }
  },
  created() {},
  computed: {},
  methods: {
    configChanged() {
      this.$refs.predictionsByMarket.loadData()
    }
  }
}
</script>
